var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tilasto" },
    [
      _c(
        "ViewTopBarBase",
        {
          attrs: {
            title: _vm.$t("common:statistics.statistics"),
            "dense-toggle-button": false,
          },
        },
        [
          _c("v-menu", {
            attrs: { bottom: "", left: "", "nudge-bottom": "40" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        { attrs: { color: "success", depressed: "" } },
                        on
                      ),
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("common:statistics.actions")) +
                            " "
                        ),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v(" more_vert "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            attrs: { disabled: !_vm.tilastoLomakeData.tilasto },
                            on: { click: _vm.avaaUusiRaporttiDialog },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("library_add")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common:statistics.saveNewReport")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              disabled: !(
                                _vm.tilastoLomakeData.raportti &&
                                _vm.voiMuokataRaporttia
                              ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.muokkaaRaporttiaDialogAuki = true
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("edit")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common:statistics.editReport")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              disabled: !(
                                _vm.tilastoLomakeData.raportti &&
                                _vm.voiMuokataRaporttia
                              ),
                            },
                            on: { click: _vm.avaaPoistaDialog },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [_c("v-icon", [_vm._v("delete")])],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("common:statistics.deleteReport")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "height-100-minus-ylaosa pa-3", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("FormTilasto", {
                    attrs: {
                      tilastot: _vm.tilastot,
                      raportit: _vm.tilastonRaportit,
                      "lataa-tilastoja":
                        _vm.lataaTilastoja || _vm.lataaTilastoa,
                      "lataa-raportteja": _vm.lataaRaportteja,
                      "nayta-paamiehet": _vm.isStaff,
                    },
                    on: { submit: _vm.haeTilastorivit },
                    model: {
                      value: _vm.tilastoLomakeData,
                      callback: function ($$v) {
                        _vm.tilastoLomakeData = $$v
                      },
                      expression: "tilastoLomakeData",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  { staticClass: "pb-4 pt-2" },
                  [
                    _vm.kuvaus
                      ? _c(
                          "v-card",
                          {
                            staticClass: "pa-6",
                            attrs: {
                              "min-height": "80px",
                              "max-height": "120px",
                            },
                          },
                          [
                            _c("CardTitle", {
                              attrs: {
                                text: _vm.$t(
                                  "common:statistics.descriptionReport"
                                ),
                              },
                            }),
                            _vm._v(" " + _vm._s(_vm.kuvaus) + " "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "height-100" },
            [
              _c("Pivot", {
                ref: "pivot",
                attrs: {
                  height: "100%",
                  report: _vm.report,
                  toolbar: "",
                  beforetoolbarcreated: _vm.flexmonsterYlapalkki,
                  "license-key": _vm.flexmonsterLisenssi,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.$t("common:statistics.newReport"),
            "dialog-auki": _vm.uusiRaporttiDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "650",
          },
          on: { close: _vm.suljeUusiRaporttiDialog },
        },
        [
          _c("FormTilastoRaportti", {
            staticClass: "pb-4",
            attrs: {
              loading: _vm.tallentaaRaporttia,
              "nayta-nakyvyysvalinnat": _vm.isSuperuser,
              "submit-teksti": _vm.$t("common:statistics.saveReport"),
            },
            on: {
              close: function ($event) {
                _vm.raporttiDialogAuki = false
              },
              submit: _vm.tallennaUusiRaportti,
            },
            model: {
              value: _vm.raporttiLomakeData,
              callback: function ($$v) {
                _vm.raporttiLomakeData = $$v
              },
              expression: "raporttiLomakeData",
            },
          }),
        ],
        1
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.$t("common:statistics.editReport"),
            "dialog-auki": _vm.muokkaaRaporttiaDialogAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "650",
          },
          on: {
            close: function ($event) {
              _vm.muokkaaRaporttiaDialogAuki = false
            },
          },
        },
        [
          _c("FormTilastoRaportti", {
            staticClass: "pb-4",
            attrs: {
              loading: _vm.tallentaaRaporttia,
              "nayta-nakyvyysvalinnat": _vm.isSuperuser,
              "submit-teksti": _vm.$t("common:saveChanges"),
            },
            on: {
              close: function ($event) {
                _vm.raporttiDialogAuki = false
              },
              submit: _vm.muokkaaRaporttia,
            },
            model: {
              value: _vm.raporttiLomakeData,
              callback: function ($$v) {
                _vm.raporttiLomakeData = $$v
              },
              expression: "raporttiLomakeData",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }