export const paloitteleArvot = (arvot, erotin = '%2C', maxPituus = 1900) => {
  // Apufunktio joka jakaa listan arvot sopivan pituisiin merkkijonoihin eroteltuna pilkulla
  // Url maksimipituus on 2000 merkkiä, oletetaan urlin alku on alle 100 merkkiä

  // HUOM! arvot eivät saa sisältää erotinta!
  const erotinPituus = erotin.length
  let merkkijono = arvot.join(erotin)
  const idLista = []

  let tmpMerkkijono
  let viimeinenErotinIndeksi
  let j = 0

  // kokeillaan max miljoona kertaa
  while (j < 1000000) {
    if (merkkijono.length <= maxPituus) {
      idLista.push(merkkijono)
      break
    }

    tmpMerkkijono = merkkijono.slice(0, maxPituus)

    // etsitään viimeinen pilkku
    for (let i = 0; i <= maxPituus - erotinPituus; i++) {
      if (tmpMerkkijono.slice(maxPituus - erotinPituus - i, maxPituus - i) === erotin) {
        viimeinenErotinIndeksi = maxPituus - i  // erotinta seuraava indeksi
        break
      }
    }

    if (!viimeinenErotinIndeksi) {
      // ei erotinta eli jotain on pielessä
      throw new Error('Erotinta ei lyödy')
    }

    idLista.push(tmpMerkkijono.slice(0, viimeinenErotinIndeksi))
    merkkijono = merkkijono.slice(viimeinenErotinIndeksi)
    viimeinenErotinIndeksi = null
    j += 1
  }

  // korvataan erottimet pilkulla
  return idLista.map(str => str.split(erotin).join(','))
}
