<template>
  <div
    class="tilasto"
  >
    <ViewTopBarBase
      :title="$t('common:statistics.statistics')"
      :dense-toggle-button="(false)"
    >
      <v-menu
        bottom
        left
        nudge-bottom="40"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            v-on="on"
          >
            {{ $t('common:statistics.actions') }}
            <v-icon right>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item
              :disabled="!tilastoLomakeData.tilasto"
              @click="avaaUusiRaporttiDialog"
            >
              <v-list-item-avatar>
                <v-icon>library_add</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:statistics.saveNewReport') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!(tilastoLomakeData.raportti && voiMuokataRaporttia)"
              @click="muokkaaRaporttiaDialogAuki = true"
            >
              <v-list-item-avatar>
                <v-icon>edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:statistics.editReport') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!(tilastoLomakeData.raportti && voiMuokataRaporttia)"
              @click="avaaPoistaDialog"
            >
              <v-list-item-avatar>
                <v-icon>delete</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:statistics.deleteReport') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </ViewTopBarBase>

    <v-container
      fluid
      class="height-100-minus-ylaosa pa-3"
    >
      <v-row class="mb-3">
        <v-col cols="6">
          <FormTilasto
            v-model="tilastoLomakeData"
            :tilastot="tilastot"
            :raportit="tilastonRaportit"
            :lataa-tilastoja="lataaTilastoja || lataaTilastoa"
            :lataa-raportteja="lataaRaportteja"
            :nayta-paamiehet="isStaff"
            @submit="haeTilastorivit"
          >
          </FormTilasto>
        </v-col>

        <v-col cols="6">
          <div class="pb-4 pt-2">
            <v-card
              v-if="kuvaus"
              class="pa-6"
              min-height="80px"
              max-height="120px"
            >
              <CardTitle :text="$t('common:statistics.descriptionReport')" />
              {{ kuvaus }}
            </v-card>
          </div>
        </v-col>
      </v-row>

      <v-row class="height-100">
        <Pivot
          ref="pivot"
          height="100%"
          :report="report"
          toolbar
          :beforetoolbarcreated="flexmonsterYlapalkki"
          :license-key="flexmonsterLisenssi"
        >
        </Pivot>
      </v-row>
    </v-container>

    <CardDialog
      :title="$t('common:statistics.newReport')"
      :dialog-auki="uusiRaporttiDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="650"
      @close="suljeUusiRaporttiDialog"
    >
      <FormTilastoRaportti
        v-model="raporttiLomakeData"
        class="pb-4"
        :loading="tallentaaRaporttia"
        :nayta-nakyvyysvalinnat="isSuperuser"
        :submit-teksti="$t('common:statistics.saveReport')"
        @close="raporttiDialogAuki= false"
        @submit="tallennaUusiRaportti"
      />
    </CardDialog>

    <CardDialog
      :title="$t('common:statistics.editReport')"
      :dialog-auki="muokkaaRaporttiaDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="650"
      @close="muokkaaRaporttiaDialogAuki = false"
    >
      <FormTilastoRaportti
        v-model="raporttiLomakeData"
        class="pb-4"
        :loading="tallentaaRaporttia"
        :nayta-nakyvyysvalinnat="isSuperuser"
        :submit-teksti="$t('common:saveChanges')"
        @close="raporttiDialogAuki = false"
        @submit="muokkaaRaporttia"
      />
    </CardDialog>
  </div>
</template>

<script>
import { Pivot } from 'vue-flexmonster'
import 'flexmonster/flexmonster.css'
import '@/assets/scss/flexmonsterStyles.scss'

import {
  TilastoparametriSarakeError,
  TilastoparametriTyyppiError,
  TilastoparametriHakukenttaError,
  TilastoparametriOtsikkoError,
} from '@/utils/errors'

import { paloitteleArvot } from '@/utils/tilasto'

import { FormTilasto, FormTilastoRaportti } from '@/components'

import { mapState } from 'vuex'

export default {
  components: {
    Pivot,
    FormTilasto,
    FormTilastoRaportti,
  },

  data () {
    return {
      kaikkiRaportit: [],
      lataaRaportteja: false,
      lataaTilastoa: false,
      lataaTilastoja: false,
      muokkaaRaporttiaDialogAuki: false,
      poistaaRaporttia: false,
      poistaDialogAuki: false,
      raporttiaEiValittuVirhe: false,
      raporttiLomakeData: {
        nimi: null,
        kuvaus: null,
        paamiehille: false,
        paakayttajille: false,
      },
      tallentaaRaporttia: false,
      tilastoLomakeData: {
        tilasto: null,
        raportti: null,
        paamiehet: [],
      },
      tilastonRaportit: [],
      tilastot: [],
      uusiRaporttiDialogAuki: false,
      voiMuokataRaporttia: false,
      report: {
        options: {
          showDefaultSlice: false,
          showEmptyData: false,
        },
        localization: this.$flexmonsterKaannokset(),
      },
    }
  },

  computed: {
    kuvaus () {
      if (this.tilastoLomakeData.raportti) {
        return this.tilastoLomakeData.raportti.kuvaus
      }
      return ''
    },

    flexmonsterLisenssi () {
      return process.env.VUE_APP_FLEXMONSTER_LISENSSIAVAIN
    },

    ...mapState({
      isSuperuser: state => state.user.isSuperuser,
      isStaff: state => state.user.isStaff,
    }),
  },

  watch: {
    'tilastoLomakeData.tilasto' (tilasto) {
      this.tilastonRaportit = this.kaikkiRaportit.filter(raportti => raportti.tilasto === tilasto.id)
      this.tilastoLomakeData.raportti = null
    },

    'tilastoLomakeData.raportti' (raportti) {
      if (!raportti) {
        return
      }

      // käyttäjä voi muokata vain itse tekemiään raportteja
      this.voiMuokataRaporttia = raportti.voiko_muokata

      this.raporttiLomakeData = {
        nimi: raportti.nimi,
        kuvaus: raportti.kuvaus,
        paamiehille: raportti.paamiehille,
        paakayttajille: raportti.paakayttajille,
      }

      // palautetaan vakio-formatointi
      this.$refs.pivot.flexmonster.setFormat({ name: '' })

      // resetoidaan konditionaaliset formatoinnit
      this.$refs.pivot.flexmonster.removeAllConditions()
      try {
        this.$refs.pivot.flexmonster.runQuery(raportti.data.slice)
      } catch (ex) {
        console.log('TilastoNayta.watch ex:', ex)
      }
      this.$refs.pivot.flexmonster.setOptions(raportti.data.options)

      raportti.data.formats.forEach(
        formaatti => this.$refs.pivot.flexmonster.setFormat(formaatti, formaatti.kentanNimi)
      )

      raportti.data.conditions.forEach(
        ehto => this.$refs.pivot.flexmonster.addCondition(ehto)
      )
    },

    kaikkiRaportit (raportit) {
      this.haeTilastot(raportit)

      if (this.tilastoLomakeData.tilasto) {
        this.tilastonRaportit = raportit.filter(
          raportti => raportti.tilasto === this.tilastoLomakeData.tilasto.id
        )
      } else {
        this.tilastonRaportit = []
      }
    },
  },

  mounted () {
    this.haeRaportit()
  },

  methods: {
    avaaUusiRaporttiDialog () {
      // resetoi lomake
      this.raporttiLomakeData = {
        nimi: '',
        kuvaus: '',
        paamiehille: false,
        paakayttajille: false,
      }

      this.uusiRaporttiDialogAuki = true
    },

    suljeUusiRaporttiDialog () {
      // palauta lomake
      this.raporttiLomakeData = this.tilastoLomakeData.raportti

      this.uusiRaporttiDialogAuki = false
    },

    async haeTilastorivit () {
      this.lataaTilastoa = true

      const tilasto = this.tilastoLomakeData.tilasto

      let paamiehet = ''
      if (!this.tilastoLomakeData.kaikkiPaamiehet) {
        paamiehet = this.tilastoLomakeData.paamies.map(pm => pm.id).join(',')
      }


      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.Tilastorivi,
          {
            method: 'GET',
            query: {
              tilasto: tilasto.id,
              paamiehet,
              psize: 100000000,
            },
          },
        )

        if (!response.success) {
          throw new this.$HttpError(response)
        }

        let data = response.result.body.results.map(rivi => JSON.parse(rivi.data))
        if (data.length === 0) {
          this.$naytaVirheilmoitus(this.$t('common:statistics.noStatisticRows'))
          return
        }

        let otsikot = {}

        if (tilasto.parametrit) {
          /**
          * katsotaan tilaston parametrit ja haetaan sarakkeiden tekstimuotoiset arvot rajapinnasta
          * sarake on muotoa
          * {
          *   nimi: sarakkeenNimi, (pakollinen)
          *   otsikko: sarakkeenOtsikko, (pakollinen)
          *   tyyppi: sarakkeenTyyppi, (pakollinen)
          *   url: urlRajapintaan,
          *   hakukentta: objektinKenttaJotaKaytetaan
          * }
          */
          for (const sarake of tilasto.parametrit.sarakkeet) {
            // tarkista että sarake löytyy tilastosta
            if (!Object.keys(data[0]).includes(sarake.nimi)) {
              throw new TilastoparametriSarakeError(tilasto, sarake)
            }

            // tarkista että sarakkeella on tyyppi
            if (!sarake.tyyppi) {
              throw new TilastoparametriTyyppiError(tilasto, sarake)
            }

            // tarkista että sarakkeella on otsikko
            if (!sarake.tyyppi) {
              throw new TilastoparametriOtsikkoError(tilasto, sarake)
            }

            // hae data rajapinnasta
            if (sarake.url) {
              let uniikit_idt = new Set()
              data.forEach(rivi => {
                uniikit_idt.add(rivi[sarake.nimi])
              })

              const uudetArvot = await this.haeUudetArvot(
                Array.from(uniikit_idt),
                sarake
              )

              data = data.map(rivi => {
                const uusiRivi = {
                  ...rivi,
                  [sarake.nimi]: uudetArvot[rivi[sarake.nimi]],
                }

                return uusiRivi
              })
            }

            // aseta sarakkeen otsikko ja tyyppi
            otsikot[sarake.nimi] = {
              caption: sarake.otsikko,
              type: sarake.tyyppi,
            }
          }
        }
        // paivitetaan data flexmonsterille
        this.$refs.pivot.flexmonster.setReport({
          ...this.report,
          dataSource: {
            data,
            mapping: otsikot,
          }
        })

        // resetoidaan raportti
        this.tilastoLomakeData.raportti = null
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:statistics.loadingStatisticFailed'))
      } finally {
        this.lataaTilastoa = false
      }
    },

    async haeUudetArvot (idt, sarake) {
      const url = sarake.url.endsWith('/') ? sarake.url : sarake.url + '/'
      const idLista = paloitteleArvot(idt)
      const uudetArvot = {}
      let responseData

      for (const idtMerkkijono of idLista) {
        const response = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            method: 'GET',
            url,
            query: {
              id__in: idtMerkkijono,
              psize: 100000,
            },
          },
        )

        if (!response.success) {
          throw new this.$HttpError(response)
        }

        responseData = response.result.body.results

        // löytyykö hakukenttä rajapinnan datasta
        if (!Object.keys(responseData[0]).includes(sarake.hakukentta)) {
          throw new TilastoparametriHakukenttaError(this.tilastoLomakeData.tilasto, sarake)
        }

        responseData.forEach(objekti => {
          Object.assign(uudetArvot, { [objekti.id]: objekti[sarake.hakukentta] })
        })
      }

      return uudetArvot
    },

    async haeRaportit () {
      this.lataaRaportteja = true

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.FlexmonsterRaportti,
          {
            method: 'GET',
            query: {
              psize: 10000,
            },
          },
        )

        const raportit = response.result.body.results

        this.kaikkiRaportit = raportit.map(raportti => {
          raportti.data = JSON.parse(raportti.data)
          return raportti
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:statistics.loadingReportsFailed'))
      } finally {
        this.lataaRaportteja = false
      }
    },

    async haeTilastot (raportit) {
      this.lataaTilastoja = true

      const tilasto_idt = raportit.map(raportti => raportti.tilasto)

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.Tilasto,
          {
            method: 'GET',
            query: {
              // pääkäyttäjälle palautetaan kaikki tilastot vaikka raportteja ei vielä olisikaan
              tilastot: tilasto_idt.join(','),
              psize: 10000,
            },
          },
        )

        if (!response.success) {
          throw new this.$HttpError(response)
        }

        const tilastot = response.result.body.results

        this.tilastot = tilastot.map(tilasto => {
          if (tilasto.parametrit) tilasto.parametrit = JSON.parse(tilasto.parametrit)
          return tilasto
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:statistics.loadingStatisticsFailed'))
      } finally {
        this.lataaTilastoja = false
      }
    },

    keraaData () {
      const data = {
        slice: {},
        options: {},
        conditions: {},
        formats: {},
      }

      // laitetaan valitut suureet ja asetukset (esim. tilastonäkymän tyyppi) objektiin
      data.slice.rows = this.$refs.pivot.flexmonster.getRows()
      data.slice.columns = this.$refs.pivot.flexmonster.getColumns()
      data.slice.measures = this.$refs.pivot.flexmonster.getMeasures()
      data.slice.reportFilters = this.$refs.pivot.flexmonster.getReportFilters()
      data.options = this.$refs.pivot.flexmonster.getOptions()

      // tallennetaan filtterit

      data.slice.rows = data.slice.rows.map(rivi => ({
        ...rivi,
        filter: this.$refs.pivot.flexmonster.getFilter(rivi.uniqueName),
      }))

      data.slice.columns = data.slice.columns.map(sarake => ({
        ...sarake,
        filter: this.$refs.pivot.flexmonster.getFilter(sarake.uniqueName),
      }))

      data.slice.measures = data.slice.measures.map(arvo => ({
        ...arvo,
        filter: this.$refs.pivot.flexmonster.getFilter(arvo.uniqueName),
      }))

      data.slice.reportFilters = data.slice.reportFilters.map(suodatin => ({
        ...suodatin,
        filter: this.$refs.pivot.flexmonster.getFilter(suodatin.uniqueName),
      }))

      data.slice.measures.forEach(arvo => {
        // flexmonsterissa voi olla bugi että kyseisten kenttien antaminen runQuery-metodille sotkee valittavia aggregaattoreita
        delete arvo.availableAggregations
        delete arvo.availableAggregationsCaptions

        // flexmonster tallentaa turhia otsikoita values-kentille, poistetaan ne
        delete arvo.caption
      })

      data.conditions = this.$refs.pivot.flexmonster.getAllConditions()

      data.formats = data.slice.measures.map(arvo => {
        let formaatti = this.$refs.pivot.flexmonster.getFormat(arvo.uniqueName)
        formaatti.kentanNimi = arvo.uniqueName
        return formaatti
      })

      return data
    },

    async tallennaUusiRaportti () {
      this.tallentaaRaporttia = true

      const data = this.keraaData()

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.FlexmonsterRaportti,
          {
            method: 'POST',
            url: '',
            body: {
              nimi: this.raporttiLomakeData.nimi,
              kuvaus: this.raporttiLomakeData.kuvaus,
              tilasto: this.tilastoLomakeData.tilasto.id,
              data: JSON.stringify(data),
              paamiehille: !!this.raporttiLomakeData.paamiehille,
              paakayttajille: !!this.raporttiLomakeData.paakayttajille,
            }
          }
        )

        if (response.success) {
          this.$naytaOnnistumisilmoitus(this.$t('common:statistics.savingReportSuccess'))

          // päivitä raportit
          await this.haeRaportit()

          const raporttiId = response.result.body.id

          // valitse luotu raportti
          this.tilastoLomakeData.raportti = this.tilastonRaportit.filter(raportti => raportti.id === raporttiId)[0]
        } else {
          throw new this.$HttpError(response)
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.tallentaaRaporttia = false
        this.uusiRaporttiDialogAuki = false
      }
    },

    async muokkaaRaporttia () {
      this.tallentaaRaporttia = true

      const data = this.keraaData()

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.FlexmonsterRaportti,
          {
            method: 'PUT',
            url: `${this.tilastoLomakeData.raportti.id}/`,
            body: {
              nimi: this.raporttiLomakeData.nimi,
              kuvaus: this.raporttiLomakeData.kuvaus,
              tilasto: this.tilastoLomakeData.raportti.tilasto,
              data: JSON.stringify(data),
              paamiehille: !!this.raporttiLomakeData.paamiehille,
              paakayttajille: !!this.raporttiLomakeData.paakayttajille,
            },
          }
        )

        if (response.success) {
          this.$naytaOnnistumisilmoitus(this.$t('common:statistics.savingReportSuccess'))

          // päivitä raportit
          await this.haeRaportit()

          const raporttiId = response.result.body.id

          // valitse muokattu raportti
          this.tilastoLomakeData.raportti = this.tilastonRaportit.filter(raportti => raportti.id === raporttiId)[0]
        } else {
          throw new this.$HttpError(response)
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.tallentaaRaporttia = false
        this.muokkaaRaporttiaDialogAuki = false
      }
    },

    async avaaPoistaDialog () {
      const result = await this.$swal({
          title: this.$t('common:statistics.sureYouWantDeleteReport') + ` ${this.tilastoLomakeData.raportti.nimi}?`,
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Poista raportti',
          cancelButtonText: 'Peruuta',
        })

      if (!result.value) return

      this.poistaRaportti(this.tilastoLomakeData.raportti)
    },

    async poistaRaportti (raportti) {
      this.poistaaRaporttia = true

      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.FlexmonsterRaportti,
          {
            method: 'DELETE',
            url: `${raportti.id}/`,
          }
        )

        if (response.success) {
          this.$naytaOnnistumisilmoitus(this.$t('common:statistics.deletingReportSuccess'))
          this.tilastoLomakeData.raportti = null

          // päivitä raportit
          this.haeRaportit()
        } else {
          throw new this.$HttpError(response)
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.poistaaRaporttia = false
      }
    },

    // poistetaan turhat napit yläpalkista
    flexmonsterYlapalkki (ylapalkki) {
      let napit = ylapalkki.getTabs()

      // connect, open ja save napit
      napit.splice(0, 3)

      ylapalkki.getTabs = () => {
        return napit
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.tilasto {
  height: 100vh;
}

.height-100-minus-ylaosa {
  height: calc(100% - 224px);
}

.height-100 {
  height: 100%;
}

fm-pivot > div {
  height: 100%;
}

</style>
